

































import { Component, Vue } from "vue-property-decorator";
import { Debounce } from "@/app/shared/utils/debounce";

import { EditDocumentRoute } from "@/app/domains/document/document.route";

import Project from "@/app/shared/models/project.model";
import Document from "@/app/domains/document/models/document.model";
import Languages, { LanguageEnum } from "@/app/shared/constants/languages";

import DocumentService from "@/app/shared/services/document.service";

@Component({
  beforeRouteEnter(to, from, next): void {
    next((vm) => {
      (vm as ReadDocument).getDocumentById(parseInt(vm.$route.params.id));
    });
  },
  beforeRouteUpdate(to, from, next): void {
    next();
    (this as ReadDocument).getDocumentByIdThrottle.invoke(this);
  },
})
export default class ReadDocument extends Vue {
  content = "";
  document: Document | null = null;

  private languages = Languages;
  private language: number | null = null;

  private get project(): Project {
    return this.$store.getters["projectsModule/getSelectedProject"];
  }

  private get editUrl(): {
    name: string | undefined;
    params: { [key: string]: any };
    query: { [key: string]: any };
  } {
    return {
      name: EditDocumentRoute.name,
      params: {
        id: this.document?.id?.toString(),
        projectId: this.project?.id?.toString(),
      },
      query: {
        lang: this.language?.toString(),
      },
    };
  }

  private getDocumentByIdThrottle = new Debounce((instance: ReadDocument) => {
    instance.content = "";
    instance.language = LanguageEnum.English;
    instance.getDocumentById(parseInt(instance.$route.params.id));
  }, 100);

  private changeLanguage(value: number): void {
    if (this.document?.contents) {
      const data = this.document?.contents.find((i) => i.language === value);
      if (data) {
        this.content = data?.content;
        return;
      }
    }
    this.content = "";
  }

  private async getDocumentById(id: number): Promise<void> {
    try {
      this.document = (await DocumentService.getDocumentById(id)).data;
      this.language = LanguageEnum.English;
      if (this.document.contents?.length) {
        const engContent = this.document.contents.find(
          (d) => d.language === LanguageEnum.English
        );
        if (engContent) this.content = engContent.content;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
