export enum LanguageEnum {
  English,
  Russian,
}

const languages: Array<{ key: LanguageEnum; value: string }> = Object.keys(
  LanguageEnum
)
  .filter((l) => typeof LanguageEnum[l as any] === "string")
  .map((_, index) => {
    return {
      key: index,
      value: LanguageEnum[index],
    };
  });

export default languages;
